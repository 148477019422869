<template>
    <div class="flex flex-col items-center justify-center space-y-2">
        <div v-if="blok.has_background"
            :id="blok._uid"
            :class="{ 'relative flex size-20 items-center justify-between rounded-full bg-gray-100'
                : blok.has_background }">
            <v-icon class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-black text-center text-8xl"
                :icon="iconName" />
        </div>

        <v-icon v-if="!blok.has_background"
            class="relative fill-red-500 text-center text-11xl"
            :icon="iconName" />

        <div :class="{'my-md' : blok.has_background}">
            <p class="text-center font-sans-caption text-base font-bold">{{ blok.title }}</p>
        </div>

        <p class="text-center font-sans text-base">{{ blok.content }}</p>

        <nuxt-link v-if="blok.button_text"
            :to="url"
            :aria-label="blok.button_link"
            class="my-auto mt-md inline-block rounded border border-gray-100 p-xs text-center">
            <p class="inline-block text-base text-black md:text-base md:leading-5">
                {{ blok.button_text }}
            </p>
        </nuxt-link>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-text-columns' | 'sb-text-column' }>
}>();

const iconName = computed(() => properties.blok.icon);
const { format: formatSbUrl } = useSbUrl();

const url = computed(() => formatSbUrl(properties.blok.button_link?.cached_url));
</script>
